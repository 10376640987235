import React from "react";
import { Link } from "react-router-dom";

const PageNotFound = () => {
    return (
        <div className="flex flex-col justify-center items-center h-screen gap-3">
            <h1 className="text-3xl">404 - Page Not Found</h1>
            <p>The place that never existed.</p>
            <Link to="/">
                <button className="btn btn-primary">Back to Home</button>
            </Link>
        </div>
    );
};

export default PageNotFound;
