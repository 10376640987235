import { createBrowserRouter } from "react-router-dom";
import Main from "../Layout/Main";
import Blog from "../Pages/Blog/Blog";
// import BlogDetails from "../Pages/Blog/BlogDetails";
import Blogs from "../Pages/Blog/Blogs";
import Contact from "../Pages/Contact/Contact";
import Home from "../Pages/Home/Home";
import PageNotFound from "../Pages/PageNotFound/PageNotFound";
import ProjectDetails from "../Pages/Projects/ProjectDetails";
import Projects from "../Pages/Projects/Projects";
import ViewResume from "../Pages/ViewResume/ViewResume";

const router = createBrowserRouter([
    {
        path: "/",
        element: <Main />,
        errorElement: <PageNotFound />,
        children: [
            {
                path: "/",
                element: <Home />,
            },
            {
                path: "/view_resume",
                element: <ViewResume />,
            },
            {
                path: "/projects",
                element: <Projects />,
            },
            {
                path: "/project/:slug",
                element: <ProjectDetails />,
            },
            {
                path: "/writings",
                element: <Blogs />,
            },
            // {
            //     path: "/blog/:slug",
            //     element: <BlogDetails />,
            // },
            {
                path: "/contact",
                element: <Contact />,
            },
            {
                path: "/blog",
                element: <Blog />,
            },
        ],
    },
    {
        path: "/*",
        element: <PageNotFound />,
    },
]);

export default router;
