import React from "react";
import "./Project.css";
import { Link } from "react-router-dom";

const Project = ({ project }) => {
    const { title, slug, image, technologies } = project;

    return (
        <div className="card bg-base-100 shadow-xl lg:card-side slide-in-top flex">
            <figure className="lg:w-1/2 h-96 custom-full-width">
                <img src={image} alt="projects" className="w-full h-auto" />
            </figure>
            <div className="card-body lg:w-1/2 custom-full-width">
                <h2 className="card-title mb-8 text-2xl">{title}</h2>
                <div>
                    <h2 className="font-bold text-lg text-gray-400">
                        Technologies Used:
                    </h2>
                    {Object.entries(technologies).map(([key, value]) => (
                        <p key={key} className="text-gray-400">
                            <strong>
                                {key.charAt(0).toUpperCase() + key.slice(1)}:
                            </strong>{" "}
                            {value}
                        </p>
                    ))}
                </div>
                <div className="mt-6 text-end">
                    <Link to={`/project/${slug}`}>
                        <button className="btn btn-primary btn-sm w-28">
                            View
                        </button>
                    </Link>
                </div>
            </div>
        </div>
    );
};

export default Project;
