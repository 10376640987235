import React from "react";
import LoadingScreen from "../LoadingScreen/LoadingScreen";
import { useEffect } from "react";
import { useState } from "react";
import Blog from "./Blog";
import useTitle from "../../Hooks/useTitle";
import { TfiWrite } from "react-icons/tfi";

const Blogs = () => {
    const [blogs, setBlogs] = useState([]);
    const [loading, setLoading] = useState(true);

    //dynamic title
    useTitle("Writings - Md. Junaidul Islam");

    useEffect(() => {
        fetch("https://portfolio-server-beige.vercel.app/other_blogs")
            .then((res) => res.json())
            .then((data) => {
                setBlogs(data);
                setLoading(false);
            });
    }, []);

    if (loading) {
        return <LoadingScreen />;
    }

    return (
        <div>
            <h1 className="text-3xl font-extrabold text-center mb-10 relative">
                <span className="bg-clip-text text-transparent bg-gradient-to-r from-primary to-secondary">
                    Writings
                </span>
                <TfiWrite className="absolute -top-8 left-1/2 transform -translate-x-1/2 text-5xl text-primary opacity-20" />
                <div className="h-1 w-24 bg-gradient-to-r from-primary to-secondary mx-auto mt-4 rounded-full"></div>
            </h1>
            <div className="flex flex-wrap justify-center items-center gap-6">
                {blogs.map((blog) => (
                    <Blog key={blog.id} blog={blog}></Blog>
                ))}
            </div>
        </div>
    );
};

export default Blogs;
