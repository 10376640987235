import React from "react";
import { FaAward, FaLaptop, FaUsers } from "react-icons/fa";

const ActivityItem = ({ icon, title, description, index }) => (
    <div className="flex items-center mb-12">
        <div className="w-1/4 flex justify-center">
            <div className="w-12 h-12 bg-primary bg-opacity-10 rounded-full flex items-center justify-center text-3xl text-primary">
                {icon}
            </div>
        </div>
        <div className="w-3/4">
            <h3 className="text-xl font-bold mb-2 text-primary">{title}</h3>
            <p className="text-gray-600">{description}</p>
        </div>
    </div>
);

const ExtraCurricular = () => {
    const activities = [
        {
            icon: <FaLaptop className="text-2xl" />,
            title: "Participated in Workshops, Seminars and Conferences",
            description: (
                <ul className="list-disc list-inside">
                    <li>Cyber Defense Conference '23</li>
                    <li>Small Satellite (CanSat)</li>
                    <li>
                        Evolution of Cellular Communication: 2G, 3G, 4G and
                        beyond
                    </li>
                    <li>
                        Innovations in Science, Engineering and Technology
                        (ICISET 2018)
                    </li>
                    <li>Basic Robot Design & Microcontroller Programming</li>
                </ul>
            ),
        },
        {
            icon: <FaUsers className="text-2xl" />,
            title: "Position of Responsibility",
            description: (
                <>
                    <strong>Volunteer</strong>
                    <br />
                    Bangladesh Innovation Forum
                    <br />
                    Mar 2019 – Dec 2019
                </>
            ),
        },
    ];

    return (
        <div className="">
            <div className="container mx-auto px-4">
                <h1 className="text-3xl font-extrabold text-center mt-20 mb-10 relative">
                    <span className="bg-clip-text text-transparent bg-gradient-to-r from-primary to-secondary">
                        Extracurricular Activities
                    </span>
                    <FaAward className="absolute -top-8 left-1/2 transform -translate-x-1/2 text-5xl text-primary opacity-20" />
                    <div className="h-1 w-24 bg-gradient-to-r from-primary to-secondary mx-auto mt-4 rounded-full"></div>
                </h1>
                <div>
                    {activities.map((activity, index) => (
                        <ActivityItem key={index} {...activity} index={index} />
                    ))}
                </div>
            </div>
        </div>
    );
};

export default ExtraCurricular;
