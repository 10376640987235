import React from "react";
import { FaGraduationCap, FaUniversity, FaCalendarAlt } from "react-icons/fa";
import "./Education.css";

const EducationItem = ({ year, degree, institution, logo }) => (
    <div className="relative bg-base-100 rounded-lg overflow-hidden shadow-lg hover:shadow-2xl transition-all duration-300 transform hover:-translate-y-2">
        <div className="absolute top-0 left-0 w-2 h-full bg-primary"></div>
        <div className="p-6">
            <span className="inline-block px-3 py-1 text-sm font-semibold text-primary bg-primary bg-opacity-10 rounded-full mb-4">
                {logo}
            </span>
            <h3 className="text-2xl font-bold mb-2">{degree}</h3>
            <div className="flex items-center text-gray-600 mb-2">
                <FaUniversity className="mr-2" />
                <p>{institution}</p>
            </div>
            <div className="flex items-center text-gray-500 text-sm">
                <FaCalendarAlt className="mr-2" />
                <span>{year}</span>
            </div>
        </div>
    </div>
);

const Education = () => {
    const educationData = [
        {
            year: "2021",
            degree: "Bachelor of Science in Electronic and Telecommunication Engineering",
            institution:
                "International Islamic University Chittagong, Bangladesh",
            logo: "BSc",
        },
        {
            year: "2015",
            degree: "Higher School Certificate, Science",
            institution: "Hazera-Taju Degree College, Chittagong, Bangladesh",
            logo: "HSC",
        },
        {
            year: "2013",
            degree: "Secondary School Certificate, Science",
            institution:
                "Government Muslim High School, Chittagong, Bangladesh",
            logo: "SSC",
        },
    ];

    return (
        <div className="bg-gradient-to-b from-base-200 to-base-100">
            <div className="container mx-auto px-4">
                <h1 className="text-3xl font-extrabold text-center mt-20 mb-10 relative">
                    <span className="bg-clip-text text-transparent bg-gradient-to-r from-primary to-secondary">
                        Academic Overview
                    </span>
                    <FaGraduationCap className="absolute -top-8 left-1/2 transform -translate-x-1/2 text-5xl text-primary opacity-20" />
                    <div className="h-1 w-24 bg-gradient-to-r from-primary to-secondary mx-auto mt-4 rounded-full"></div>
                </h1>
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 custom-grid">
                    {educationData.map((item, index) => (
                        <EducationItem key={index} {...item} />
                    ))}
                </div>
            </div>
        </div>
    );
};

export default Education;
