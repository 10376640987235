import { RouterProvider } from "react-router-dom";
import "./App.css";
import router from "./Routes/Routes";

function App() {
    return (
        <div className="App max-w-[1440px] m-auto">
            <RouterProvider router={router} />
        </div>
    );
}

export default App;
