import React from "react";
import About from "../About/About";
import Skills from "../Skills/Skills";
import Career from "../Career/Career";
import Education from "../Education/Education";
import ExtraCurricular from "../ExtraCurricular/ExtraCurricular";
const Home = () => {
    return (
        <div>
            <About />
            <Skills />
            <Career />
            <Education />
            <ExtraCurricular />
        </div>
    );
};

export default Home;
