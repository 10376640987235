import React from "react";
import { Link } from "react-router-dom";
import ProfileCard from "../ProfileCard/ProfileCard";
import { RxAvatar } from "react-icons/rx";
import { AiOutlineFundProjectionScreen } from "react-icons/ai";
import { RiMessage2Line } from "react-icons/ri";
import { ImBlog } from "react-icons/im";
import "./Sidebar.css";

const menuItems = (
    <>
        <li>
            <Link className="font-bold" to="/">
                <div className="flex flex-col items-center m-auto">
                    <RxAvatar size={20} />
                    <p>About</p>
                </div>
            </Link>
        </li>
        <li>
            <Link className="font-bold" to="/projects">
                <div className="flex flex-col items-center m-auto">
                    <AiOutlineFundProjectionScreen size={20} />
                    <p>Works</p>
                </div>
            </Link>
        </li>
        <li>
            <Link className="font-bold" to="/contact">
                <div className="flex flex-col items-center m-auto">
                    <RiMessage2Line size={20} />
                    <p>Contact</p>
                </div>
            </Link>
        </li>
        <li>
            <Link className="font-bold" to="/writings">
                <div className="flex flex-col items-center m-auto">
                    <ImBlog size={20} />
                    <p>Writings</p>
                </div>
            </Link>
        </li>
    </>
);

const Sidebar = () => {
    return (
        <>
            <div className="navbar bg-base-200 justify-center w-full m-auto lg:hidden sticky top-0 z-20 xs-navbar">
                <ul className="menu menu-horizontal">{menuItems}</ul>
            </div>
            <div className="hidden lg:block flex-none w-fit h-fit rounded-lg">
                <div>
                    <ul className="menu p-3 bg-base-200 text-base-content divide-y divide-slate-700">
                        {menuItems}
                    </ul>
                </div>
            </div>
            <ProfileCard />
        </>
    );
};

export default Sidebar;
