import React from "react";
import { useGeolocated } from "react-geolocated";

const GeoLocation = () => {
    const { coords, isGeolocationAvailable, isGeolocationEnabled } =
        useGeolocated({
            positionOptions: {
                enableHighAccuracy: true,
                maximumAge: 0,
                timeout: Infinity,
            },
            watchPosition: false,
            userDecisionTimeout: null,
            suppressLocationOnMount: false,
            geolocationProvider: navigator.geolocation,
            isOptimisticGeolocationEnabled: true,
            watchLocationPermissionChange: false,
        });

    return !isGeolocationAvailable ? (
        <p className="text-warning">
            Your browser does not support Geolocation!
        </p>
    ) : !isGeolocationEnabled ? (
        <p className="text-error">Location is not enabled!</p>
    ) : coords ? (
        <>
            <p>Latitude: {coords.latitude}</p>
            <p>Longitude: {coords.longitude}</p>
        </>
    ) : (
        <p>Getting the location data&hellip; </p>
    );
};

export default GeoLocation;
