import React from "react";

const ThesisCard = ({ title, date, description, link }) => (
    <div className="card w-full bg-base-100 shadow-xl mb-4">
        <div className="card-body">
            <h2 className="card-title">{title}</h2>
            <p className="text-gray-500 text-sm">{date}</p>
            <p>{description}</p>
            <div className="card-actions justify-end mt-6">
                <a
                    href={link}
                    target="_blank"
                    rel="noreferrer"
                    className="btn btn-primary btn-sm w-28"
                >
                    Read
                </a>
            </div>
        </div>
    </div>
);

const Thesis = () => {
    const theses = [
        {
            title: "Data Validation using GAIT Signals of Patients to Detect Possibility of Parkinson's Disease",
            date: "We didn't publish it at any international events. · Jan 27, 2021",
            description:
                "It's based on semi-supervised machine learning and the SVM algorithm.",
            link: "https://www.researchgate.net/publication/358211768_Data_Validation_using_GAIT_Signals_of_Patients_to_Detect_Possibility_of_Parkinson's_Disease",
        },
    ];

    return (
        <div>
            {theses.map((thesis, index) => (
                <ThesisCard key={index} {...thesis} />
            ))}
        </div>
    );
};

export default Thesis;
