import React, { useRef } from "react";
import emailjs from "@emailjs/browser";
import Swal from "sweetalert2";
import useTitle from "../../Hooks/useTitle";
import { UserIP } from "../../Components/UserIP/UserIP.js";
import GeoLocation from "../../Components/GeoLocation/GeoLocation";

const ViewResume = () => {
    const form = useRef();
    const { error, ipAddress, city, country, latitude, longitude } = UserIP();
    //dynamic title
    useTitle("View Resume - Md. Junaidul Islam");

    const sendEmail = (e) => {
        e.preventDefault();

        emailjs
            .sendForm(
                "service_54xq1va",
                "template_pvwb6g8",
                form.current,
                "Aeknf6WI4GBB_RfP8"
            )
            .then(
                (result) => {
                    form.current.reset();
                    Swal.fire({
                        title: "Thanks!",
                        text: "Click here to view my resume.",
                        icon: "success",
                        confirmButtonColor: "#3ABEF8",
                        confirmButtonText:
                            '<a href="https://drive.google.com/file/d/1ipLPbbzBzelk1C1TseJNPUL_abNaxxMi/view?usp=sharing" target="_blank" rel="noreferrer">View</a>',
                        background: "#0F1829",
                        color: "#A8B9E1",
                    });
                    console.log(result);
                },
                (error) => {
                    Swal.fire({
                        title: "Failed!",
                        text: "Something went wrong!",
                        icon: "error",
                        confirmButtonColor: "#3ABEF8",
                        confirmButtonText: "OK",
                        background: "#0F1829",
                        color: "#A8B9E1",
                    });
                    console.log(error.text);
                }
            );
    };

    return (
        <>
            <h2 className="text-center text-xl font-bold mb-6">
                Please, Introduce yourself first.
            </h2>
            <div className="w-full flex">
                <div className="w-full flex flex-col justify-center items-center">
                    <div className="card w-full max-w-sm shadow-2xl bg-base-100">
                        <form
                            ref={form}
                            onSubmit={sendEmail}
                            className="card-body"
                        >
                            <div className="form-control w-full">
                                <label className="label">
                                    <span className="label-text">Name</span>
                                </label>
                                <input
                                    name="user_name"
                                    type="text"
                                    placeholder="Name"
                                    className="input input-bordered input-primary"
                                    required
                                />
                            </div>
                            <div className="form-control">
                                <label className="label">
                                    <span className="label-text">Email</span>
                                </label>
                                <input
                                    name="user_email"
                                    type="email"
                                    placeholder="Email"
                                    className="input input-bordered input-primary"
                                    required
                                />
                            </div>
                            <div className="form-control w-full">
                                <label className="label">
                                    <span className="label-text">
                                        Occupation
                                    </span>
                                </label>
                                <input
                                    name="occupation"
                                    type="text"
                                    placeholder="Occupation"
                                    className="input input-bordered input-primary"
                                    required
                                />
                            </div>
                            <div className="form-control w-full hidden">
                                <input
                                    name="ip_address"
                                    type="text"
                                    value={
                                        ipAddress ? ipAddress : error.message
                                    }
                                />
                            </div>
                            <div className="form-control w-full hidden">
                                <input
                                    name="city_name"
                                    type="text"
                                    value={city ? city : error.message}
                                />
                            </div>
                            <div className="form-control w-full hidden">
                                <input
                                    name="country_name"
                                    type="text"
                                    value={country ? country : error.message}
                                />
                            </div>
                            <div className="form-control w-full hidden">
                                <input
                                    name="latitude"
                                    type="text"
                                    value={latitude ? latitude : error.message}
                                />
                            </div>
                            <div className="form-control w-full hidden">
                                <input
                                    name="longitude"
                                    type="text"
                                    value={
                                        longitude ? longitude : error.message
                                    }
                                />
                            </div>
                            <div className="form-control mt-6">
                                <input
                                    className="btn btn-primary"
                                    type="submit"
                                    value="Submit"
                                />
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <div className="text-center mt-12">
                <h4 className="text-xl mb-3 font-bold">Your Information</h4>
                <div>
                    {error ? (
                        <p className="text-error">{error.message}</p>
                    ) : (
                        <div>
                            <p>IP Address: {ipAddress || "Unknown"}</p>
                            <p>City: {city || "Unknown"}</p>
                            <p>Country: {country || "Unknown"}</p>
                        </div>
                    )}
                </div>
                <GeoLocation></GeoLocation>
            </div>
        </>
    );
};

export default ViewResume;
