import React from "react";
import useTitle from "../../Hooks/useTitle";
import { FaUser } from "react-icons/fa"; // Add this import

const About = () => {
    //dynamic title
    useTitle("Md. Junaidul Islam");

    return (
        <div id="about">
            <h1 className="text-3xl font-extrabold text-center mb-6 relative">
                <span className="bg-clip-text text-transparent bg-gradient-to-r from-primary to-secondary">
                    About Me
                </span>
                <FaUser className="absolute -top-8 left-1/2 transform -translate-x-1/2 text-5xl text-primary opacity-20" />
                <div className="h-1 w-24 bg-gradient-to-r from-primary to-secondary mx-auto mt-4 rounded-full"></div>
            </h1>
            <p className="text-justify">
                Hello, my name is Md. Junaidul Islam. As a software developer, I
                have refined my skills through practical experience with various
                technologies. My proficiency includes Python, Django MVT, and
                Django REST Framework for backend development, along with React
                and Next.js for frontend implementation. In DBMS, I have
                knowledge of SQL and adept at utilizing MySQL and PostgreSQL.
                Currently exploring Java under my supervisor at the company.
            </p>
            <p className="my-4 text-justify">
                My journey started to find an answer to a question: "How does a
                computer understand what I am writing?" This curiosity led me to
                explore how compilers translate code into machine language and
                then start solving problems using C and C++. Later, I overcame
                the confusion of different tech stacks, the overwhelm of
                'tutorial hell,' following the wrong roadmaps, and
                procrastination, transforming these challenges into strengths
                that have refined my skills. I developed analytical and critical
                thinking skills while doing competitive programming and working
                on academic and extracurricular activities, which will help me
                do things more efficiently. As a late bloomer, I learn the most
                when encounter bugs and try to solve them. I also enjoy learning
                new technologies and optimizing existing projects for better
                time or space complexity.
            </p>
            <p className="text-justify">
                Last but not least, I frequently struggle to express myself,
                which leads to a poor impression until people have a chance to
                get to know me and my pleasant attitude. One more thing: I like
                to keep things simple and to the point, just like this
                portfolio.
            </p>
        </div>
    );
};

export default About;
