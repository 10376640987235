import React from "react";
import { useSpring, animated } from "@react-spring/web";
import {
    SiC,
    SiCplusplus,
    SiCodeforces,
    SiCodechef,
    SiLeetcode,
    SiPython,
    SiDjango,
    SiMysql,
    SiSqlite,
    SiPostgresql,
    SiHtml5,
    SiCss3,
    SiBootstrap,
    SiTailwindcss,
    SiJavascript,
    SiReact,
    SiNextdotjs,
    SiGit,
    SiGithub,
} from "react-icons/si";
import { FaJava } from "react-icons/fa6";
import "./Skills.css";

const Skills = () => {
    const props = useSpring({
        from: { opacity: 0, transform: "translateY(100px)" },
        to: { opacity: 1, transform: "translateY(0)" },
        config: { duration: 500 },
    });

    return (
        <animated.div style={props} id="skills">
            <h1 className="text-3xl font-extrabold text-center mt-20 mb-10 relative">
                <span className="bg-clip-text text-transparent bg-gradient-to-r from-primary to-secondary">
                    Current Tech Stack and Languages
                </span>
                <SiCodeforces className="absolute -top-8 left-1/2 transform -translate-x-1/2 text-5xl text-primary opacity-20" />
                <div className="h-1 w-24 bg-gradient-to-r from-primary to-secondary mx-auto mt-4 rounded-full"></div>
            </h1>
            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-12 m-6 custom-grid-cols-2">
                {/* Competitive Programming */}
                <div className="flex flex-col items-center space-y-2">
                    <div className="flex space-x-2">
                        <SiC className="text-2xl" />
                        <SiCplusplus className="text-2xl" />
                        <SiCodeforces className="text-2xl" />
                        <SiCodechef className="text-2xl" />
                        <SiLeetcode className="text-2xl" />
                    </div>
                    <p>C/C++/Competitive Programming</p>
                    <progress
                        className="progress progress-primary w-full"
                        value="70"
                        max="100"
                    ></progress>
                </div>
                {/* Backend */}
                <div className="flex flex-col items-center space-y-2">
                    <div className="flex space-x-2">
                        <FaJava className="text-2xl" />
                    </div>
                    <p>Java</p>
                    <progress
                        className="progress progress-primary"
                        value="70"
                        max="100"
                    ></progress>
                </div>
                <div className="flex flex-col items-center space-y-2">
                    <div className="flex space-x-2">
                        <SiPython className="text-2xl" />
                        <SiDjango className="text-2xl" />
                    </div>
                    <p>Python/Django</p>
                    <progress
                        className="progress progress-primary"
                        value="75"
                        max="100"
                    ></progress>
                </div>
                {/* Database */}
                <div className="flex flex-col items-center space-y-2">
                    <div className="flex space-x-2">
                        <SiMysql className="text-2xl" />
                        <SiSqlite className="text-2xl" />
                        <SiPostgresql className="text-2xl" />
                    </div>
                    <p>SQL/MySQL/SQLite/PostgreSQL</p>
                    <progress
                        className="progress progress-primary"
                        value="75"
                        max="100"
                    ></progress>
                </div>
                {/* Frontend */}
                <div className="flex flex-col items-center space-y-2">
                    <div className="flex space-x-2">
                        <SiHtml5 className="text-2xl" />
                    </div>
                    <p>HTML5</p>
                    <progress
                        className="progress progress-primary"
                        value="80"
                        max="100"
                    ></progress>
                </div>
                <div className="flex flex-col items-center space-y-2">
                    <div className="flex space-x-2">
                        <SiCss3 className="text-2xl" />
                        <SiBootstrap className="text-2xl" />
                        <SiTailwindcss className="text-2xl" />
                    </div>
                    <p>CSS3/Bootstrap/TailwindCSS</p>
                    <progress
                        className="progress progress-primary"
                        value="80"
                        max="100"
                    ></progress>
                </div>
                <div className="flex flex-col items-center space-y-2">
                    <div className="flex items-center space-x-2">
                        <SiJavascript className="text-2xl" />
                    </div>
                    <p>JavaScript</p>
                    <progress
                        className="progress progress-primary"
                        value="75"
                        max="100"
                    ></progress>
                </div>
                <div className="flex flex-col items-center space-y-2">
                    <div className="flex items-center space-x-2">
                        <SiReact className="text-2xl" />
                        <SiNextdotjs className="text-2xl" />
                    </div>
                    <p>React/Next.js</p>
                    <progress
                        className="progress progress-primary"
                        value="70"
                        max="100"
                    ></progress>
                </div>
                {/* Version Control */}
                <div className="flex flex-col items-center space-y-2">
                    <div className="flex space-x-2">
                        <SiGit className="text-2xl" />
                        <SiGithub className="text-2xl" />
                    </div>
                    <p>Git/Github</p>
                    <progress
                        className="progress progress-primary"
                        value="80"
                        max="100"
                    ></progress>
                </div>
            </div>
        </animated.div>
    );
};

export default Skills;
