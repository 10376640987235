import React, { useEffect, useState } from "react";
import useTitle from "../../Hooks/useTitle";
import LoadingScreen from "../LoadingScreen/LoadingScreen";
import Project from "./Project";
import Thesis from "../Theses/Thesis";
import { IoMdCodeWorking } from "react-icons/io";
import { FaBook } from "react-icons/fa";

const Projects = () => {
    const [projects, setProjects] = useState([]);
    const [loading, setLoading] = useState(true);
    //dynamic title
    useTitle("Works - Md. Junaidul Islam");

    useEffect(() => {
        fetch("https://portfolio-server-beige.vercel.app/projects")
            .then((res) => res.json())
            .then((data) => {
                setProjects(data);
                setLoading(false);
            });
    }, []);

    if (loading) {
        return <LoadingScreen />;
    }

    return (
        <>
            <h1 className="text-3xl font-extrabold text-center mb-10 relative">
                <span className="bg-clip-text text-transparent bg-gradient-to-r from-primary to-secondary">
                    Projects
                </span>
                <IoMdCodeWorking className="absolute -top-8 left-1/2 transform -translate-x-1/2 text-5xl text-primary opacity-20" />
                <div className="h-1 w-24 bg-gradient-to-r from-primary to-secondary mx-auto mt-4 rounded-full"></div>
            </h1>
            <div className="flex flex-col gap-10">
                {projects.map((project) => (
                    <Project key={project.id} project={project}></Project>
                ))}
            </div>
            <h1 className="text-3xl font-extrabold text-center mt-20 mb-10 relative">
                <span className="bg-clip-text text-transparent bg-gradient-to-r from-primary to-secondary">
                    Publications
                </span>
                <FaBook className="absolute -top-8 left-1/2 transform -translate-x-1/2 text-5xl text-primary opacity-20" />
                <div className="h-1 w-24 bg-gradient-to-r from-primary to-secondary mx-auto mt-4 rounded-full"></div>
            </h1>
            <Thesis></Thesis>
        </>
    );
};

export default Projects;
