import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import LoadingScreen from "../LoadingScreen/LoadingScreen";
import { IoArrowBackCircleOutline } from "react-icons/io5";
import useTitle from "../../Hooks/useTitle";
import { Tab, TabGroup, TabList, TabPanel, TabPanels } from "@headlessui/react";

const ProjectDetails = () => {
    const { slug } = useParams();
    const navigate = useNavigate();

    //dynamic title
    useTitle("Project Details - Md. Junaidul Islam");

    const [project, setProject] = useState({});
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        fetch(`https://portfolio-server-beige.vercel.app/project/${slug}`)
            .then((res) => res.json())
            .then((data) => {
                setProject(data);
                setLoading(false);
            });
    }, [slug]);

    const {
        title,
        overview,
        image,
        functionalities,
        screenshots,
        technologies,
        frontend,
        backend,
        live,
    } = project;

    if (loading) {
        return <LoadingScreen />;
    }

    return (
        <>
            <div className=" mb-6">
                <button
                    className="btn btn-ghost btn-sm"
                    onClick={() => navigate(-1)}
                >
                    <IoArrowBackCircleOutline size={25} />
                </button>
            </div>

            <div className="flex flex-col gap-6 mb-12">
                <h1 className="text-3xl font-bold text-center mb-12">
                    Project Details
                </h1>
                <h2 className="text-2xl">
                    <strong>Project Name:</strong> {title}
                </h2>
                <p className="text-lg">
                    <strong>Overview:</strong> {overview}
                </p>
                <div className="flex flex-wrap gap-6 items-center justify-between">
                    <div
                        className="flex justify-center overflow-auto scrollbar scrollbar-style"
                        style={{ height: "28rem" }}
                    >
                        <img
                            src={image}
                            alt="projects"
                            style={{ height: "40rem", width: "28rem" }}
                        />
                    </div>
                    <div className="w-96 text-start">
                        <h2 className="text-2xl font-bold mb-6">
                            Functionalities:
                        </h2>
                        {functionalities.map((functionality, index) => (
                            <p key={index} className="mb-3">
                                ⁍{" "}
                                <span className="font-semibold">
                                    {functionality.name}:
                                </span>{" "}
                                {functionality.description}
                            </p>
                        ))}
                    </div>
                </div>
                <h2 className="text-2xl font-bold">Screenshots: </h2>
                {screenshots.length === 0 ? (
                    <p className="text-yellow-500">
                        Screenshots will be uploaded soon. Till then you can
                        visit through live link.
                    </p>
                ) : (
                    <TabGroup>
                        <TabList className="flex space-x-1 rounded-xl bg-blue-700/10 p-1">
                            {screenshots.map((screenshot, index) => (
                                <Tab
                                    key={index}
                                    className={({ selected }) =>
                                        selected
                                            ? "w-full py-2.5 text-sm leading-5 font-medium rounded-lg shadow bg-primary text-black"
                                            : "w-full py-2.5 text-sm leading-5 font-medium rounded-lg hover:bg-white/[0.12] hover:text-white"
                                    }
                                >
                                    Slide {index + 1}
                                </Tab>
                            ))}
                        </TabList>
                        <TabPanels className="mt-2">
                            {screenshots.map((screenshot, index) => (
                                <TabPanel
                                    key={index}
                                    className="rounded-xl p-3 border border-gray-600 position-static overflow-visible"
                                >
                                    <img
                                        src={screenshot}
                                        alt={`Screenshot ${index + 1}`}
                                        className="w-full h-96 object-contain position-relative transform transition-transform duration-500 hover:scale-125"
                                    />
                                </TabPanel>
                            ))}
                        </TabPanels>
                    </TabGroup>
                )}
                <div>
                    <h2 className="text-2xl font-bold mb-6">
                        Technologies Used:
                    </h2>
                    {Object.entries(technologies).map(([key, value]) => (
                        <p key={key}>
                            <strong>
                                {key.charAt(0).toUpperCase() + key.slice(1)}:
                            </strong>{" "}
                            {value}
                        </p>
                    ))}
                </div>
                <div className="flex flex-wrap justify-center items-center gap-6 mt-6">
                    <a
                        href={frontend}
                        className="btn btn-primary btn-sm w-40"
                        target="_blank"
                        rel="noreferrer"
                    >
                        Frontend Repo
                    </a>
                    <a
                        href={backend}
                        className="btn btn-primary btn-sm w-40"
                        target="_blank"
                        rel="noreferrer"
                    >
                        Backend Repo
                    </a>
                    <a
                        href={live}
                        className="btn btn-primary btn-sm w-40"
                        target="_blank"
                        rel="noreferrer"
                    >
                        Live
                    </a>
                </div>
            </div>
        </>
    );
};

export default ProjectDetails;
