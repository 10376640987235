import React from "react";
import profile from "../../assets/images/pro1.png";
import { FaGithub, FaLinkedin, FaFacebook } from "react-icons/fa";
import Typewriter from "typewriter-effect";
import "./ProfileCard.css";
// import { Link } from "react-router-dom";

const ProfileCard = () => {
    return (
        <div className="card lg:mt-6 bg-base-100 h-fit shadow-xl flex-none w-96 max-sm:w-full max-sm:m-auto max-sm:card-side tab-card-side">
            <figure>
                <img src={profile} alt="profile" />
            </figure>
            <div className="card-body ">
                <h2 className="card-title justify-center">
                    Md. Junaidul Islam
                </h2>
                <div className="text-center">
                    <Typewriter
                        options={{
                            strings: [
                                "Full-Stack Developer",
                                "Front-End Developer",
                                "Back-End Developer",
                                "Aspiring Software Engineer",
                            ],
                            autoStart: true,
                            loop: true,
                        }}
                    />
                </div>
                <div className="flex justify-center gap-3">
                    <a
                        href="https://www.linkedin.com/in/md-junaidul-islam/"
                        target="_blank"
                        rel="noreferrer"
                    >
                        <FaLinkedin />
                    </a>
                    <a
                        href="https://github.com/junaaid96"
                        target="_blank"
                        rel="noreferrer"
                    >
                        <FaGithub />
                    </a>
                    <a
                        href="https://www.facebook.com/junaaid96/"
                        target="_blank"
                        rel="noreferrer"
                    >
                        <FaFacebook />
                    </a>
                </div>
                <div className="card-actions justify-center mt-6">
                    {/* <Link to="/view_resume">
                        <button className="btn btn-primary btn-sm w-48 max-sm:w-40">
                            Resume
                        </button>
                    </Link> */}
                    <a
                        href="https://drive.google.com/file/d/1ipLPbbzBzelk1C1TseJNPUL_abNaxxMi/view"
                        target="_blank"
                        rel="noreferrer"
                    >
                        <button className="btn btn-primary btn-sm w-48 max-sm:w-40">
                            View Resume
                        </button>
                    </a>
                </div>
            </div>
        </div>
    );
};

export default ProfileCard;
