import React from "react";
import ReactLoading from "react-loading";

const LoadingScreen = () => {
    return (
        <div className="flex flex-col items-center mt-20">
            <ReactLoading type="spinningBubbles" color="#3ABEF8" />
            <p>Please wait</p>
        </div>
    );
};

export default LoadingScreen;
