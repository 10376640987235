import React from "react";
// import { Link } from "react-router-dom";
import "./Blog.css";

const Blog = ({ blog }) => {
    const { photo, title, description, link } = blog;
    return (
        <div className="card bg-base-100 lg:card-side shadow-xl">
            <figure className="w-full">
                <img
                    src={photo}
                    alt={title}
                    className="h-64 object-cover"
                />{" "}
            </figure>
            <div className="card-body">
                <h2 className="card-title">{title}</h2>
                <p>{description.slice(0, 200)}...</p>
                <div className="card-actions justify-end mt-6">
                    <a href={link} target="_blank" rel="noopener noreferrer">
                        <button className="btn btn-primary btn-outline btn-sm">
                            Read
                        </button>
                    </a>
                </div>
            </div>
        </div>
    );
};

export default Blog;
