import React, { useRef } from "react";
import emailjs from "@emailjs/browser";
import Swal from "sweetalert2";
import useTitle from "../../Hooks/useTitle";
import "./Contact.css";
import { FaEnvelope, FaHome } from "react-icons/fa";

const Contact = () => {
    const form = useRef();
    //dynamic title
    useTitle("Contact - Md. Junaidul Islam");

    const sendEmail = (e) => {
        e.preventDefault();

        emailjs
            .sendForm(
                "service_54xq1va",
                "template_pvwb6g8",
                form.current,
                "Aeknf6WI4GBB_RfP8"
            )
            .then(
                (result) => {
                    form.current.reset();
                    Swal.fire({
                        title: "Success!",
                        text: "Your message has been sent!",
                        icon: "success",
                        confirmButtonColor: "#3ABEF8",
                        confirmButtonText: "OK",
                        background: "#0F1829",
                        color: "#A8B9E1",
                    });

                    console.log(result);
                },
                (error) => {
                    Swal.fire({
                        title: "Failed!",
                        text: "Something went wrong!",
                        icon: "error",
                        confirmButtonColor: "#3ABEF8",
                        confirmButtonText: "OK",
                        background: "#0F1829",
                        color: "#A8B9E1",
                    });
                    console.log(error.text);
                }
            );
    };

    return (
        <>
            <h1 className="text-3xl font-extrabold text-center mb-10 relative">
                <span className="bg-clip-text text-transparent bg-gradient-to-r from-primary to-secondary">
                    Contact Me
                </span>
                <FaEnvelope className="absolute -top-8 left-1/2 transform -translate-x-1/2 text-5xl text-primary opacity-20" />
                <div className="h-1 w-24 bg-gradient-to-r from-primary to-secondary mx-auto mt-4 rounded-full"></div>
            </h1>
            <h2 className="text-center text-xl mb-12">
                Feel free to give constructive feedback
            </h2>
            <div className="w-full flex flex-wrap gap-8">
                <div className="w-full lg:w-1/2 flex flex-col justify-center items-center custom-full-width">
                    <div className="w-full shadow-2xl bg-base-100 rounded-lg">
                        <form
                            ref={form}
                            onSubmit={sendEmail}
                            className="card-body"
                        >
                            <div className="form-control w-full">
                                <label className="label">
                                    <span className="label-text">Name</span>
                                </label>
                                <input
                                    name="user_name"
                                    type="text"
                                    placeholder="Name"
                                    className="input input-bordered input-primary"
                                    required
                                />
                            </div>
                            <div className="form-control">
                                <label className="label">
                                    <span className="label-text">Email</span>
                                </label>
                                <input
                                    name="user_email"
                                    type="email"
                                    placeholder="Email"
                                    className="input input-bordered input-primary"
                                    required
                                />
                            </div>
                            <div className="form-control">
                                <label className="label">
                                    <span className="label-text">
                                        Phone (Optional)
                                    </span>
                                </label>
                                <input
                                    name="contact_number"
                                    type="number"
                                    placeholder="Phone"
                                    className="input input-bordered input-primary"
                                />
                            </div>
                            <div className="form-control">
                                <label className="label">
                                    <span className="label-text">Message</span>
                                </label>
                                <textarea
                                    name="message"
                                    className="input input-bordered input-primary resize rounded-lg h-52 pl-4 pt-3"
                                    placeholder="Your Message"
                                    required
                                ></textarea>
                            </div>
                            <div className="form-control mt-6">
                                <input
                                    className="btn btn-primary"
                                    type="submit"
                                    value="Send"
                                />
                            </div>
                        </form>
                    </div>
                </div>
                <div className="w-full lg:w-96 flex flex-col items-start custom-full-width">
                    <div className="bg-base-200 rounded-lg p-6 shadow-lg">
                        <h2 className="text-xl font-bold mb-6">
                            Contact Information
                        </h2>
                        <ul className="space-y-4">
                            <li className="flex items-center">
                                <FaEnvelope className="text-primary mr-3" />
                                <div>
                                    <span className="font-semibold">
                                        Email:
                                    </span>
                                    <a
                                        href="mailto:info.junaidulislam@gmail.com"
                                        className="ml-2 text-primary hover:underline"
                                    >
                                        info.junaidulislam@gmail.com
                                    </a>
                                </div>
                            </li>
                            {/* <li className="flex items-start">
                                <FaBuilding className="text-primary lg:text-3xl max-sm:text-3xl md:mt-1" />
                                <div>
                                    <address className="not-italic ml-3">
                                        <span className="font-semibold">
                                            Office:
                                        </span>{" "}
                                        eGeneration PLC, Ranks Business Center,
                                        Level 9 Ka-218/1, Pragati Sarani, Kuril,
                                        Dhaka 1229, Bangladesh
                                    </address>
                                </div>
                            </li> */}
                            <li className="flex items-start">
                                <FaHome className="text-primary mt-1" />
                                <div>
                                    <address className="not-italic ml-3">
                                        <span className="font-semibold">
                                            Home:
                                        </span>{" "}
                                        Wari, Dhaka 1203, Bangladesh
                                    </address>
                                </div>
                            </li>
                            <li className="flex items-center mt-6">
                                <div>
                                    <span>You can</span>
                                    <a
                                        href="https://linktr.ee/junaaid96"
                                        target="_blank"
                                        rel="noreferrer"
                                        className="ml-2 text-primary fancy-underline font-semibold"
                                    >
                                        discover more
                                    </a>{" "}
                                    <span>
                                        about my digital footprint all over the
                                        internet.
                                    </span>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Contact;
