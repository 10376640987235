import React from "react";
import { Outlet } from "react-router-dom";
import Sidebar from "../Pages/Sidebar/Sidebar";
import "./Main.css";

const updateTime = "Sep 27, 2024";

const Main = () => {
    return (
        <>
            <div className="lg:flex lg:items-center lg:justify-center lg:h-screen">
                <p className="text-center py-2 text-gray-500 lg:hidden">
                    Last updated on {updateTime}
                </p>
                <Sidebar></Sidebar>
                <div className="w-full m-auto bg-base-200 p-8 mt-10 lg:overflow-y-scroll rounded-lg outlet-height outlet-bg scrollbar scrollbar-style">
                    <p className="mb-12 text-gray-500 max-sm:hidden md:hidden lg:block">
                        Last updated on {updateTime}
                    </p>
                    <Outlet></Outlet>
                </div>
            </div>
        </>
    );
};

export default Main;
