import { useEffect, useState } from "react";

export const UserIP = () => {
    const [ipAddress, setIPAddress] = useState("");
    const [city, setCity] = useState("");
    const [country, setCountry] = useState("");
    const [latitude, setLatitude] = useState("");
    const [longitude, setLongitude] = useState("");
    const [error, setError] = useState("");

    useEffect(() => {
        fetch(
            "https://geolocation-db.com/json/f2e84010-e1e9-11ed-b2f8-6b70106be3c8"
        )
            .then((response) => response.json())
            .then((data) => {
                setIPAddress(data.IPv4);
                setCity(data.city);
                setCountry(data.country_name);
                setLatitude(data.latitude);
                setLongitude(data.longitude);
            })
            .catch((error) => setError(error));
    }, []);

    return { error, ipAddress, city, country, latitude, longitude };
};
