import React, { useEffect, useState } from "react";
import { VerticalTimeline } from "react-vertical-timeline-component";
import "react-vertical-timeline-component/style.min.css";
import LoadingScreen from "../LoadingScreen/LoadingScreen";
import { VerticalTimelineElement } from "react-vertical-timeline-component";
import { AiOutlineFundProjectionScreen } from "react-icons/ai";
// import { FaFreeCodeCamp } from "react-icons/fa";
import "./Career.css";

const Career = () => {
    const [loading, setLoading] = useState(true);
    const [careerData, setCareerData] = useState([]);

    useEffect(() => {
        fetch("https://portfolio-server-beige.vercel.app/career_data")
            .then((response) => response.json())
            .then((data) => {
                setCareerData(data);
                setLoading(false);
            });
    }, []);

    return (
        <div>
            {loading ? (
                <LoadingScreen />
            ) : (
                <div>
                    <h1 className="text-3xl font-extrabold text-center mt-20 mb-10 relative">
                        <span className="bg-clip-text text-transparent bg-gradient-to-r from-primary to-secondary">
                            Career Overview
                        </span>
                        <AiOutlineFundProjectionScreen className="absolute -top-8 left-1/2 transform -translate-x-1/2 text-5xl text-primary opacity-20" />
                        <div className="h-1 w-24 bg-gradient-to-r from-primary to-secondary mx-auto mt-4 rounded-full"></div>
                    </h1>
                    {/* in verticalTimeline component p style class wont work */}
                    <VerticalTimeline>
                        {careerData.map((data) => (
                            <VerticalTimelineElement
                                key={data.id}
                                className="vertical-timeline-element--work text-white"
                                date={
                                    <span className="lg:text-white lg:mx-3 custom-text-color">
                                        {data.duration}
                                    </span>
                                }
                                contentStyle={{
                                    background: "#3ABFF8",
                                    color: "#000",
                                }}
                                iconStyle={{
                                    background: "#3ABFF8",
                                    color: "#000",
                                }}
                                icon={<AiOutlineFundProjectionScreen />}
                            >
                                <h3 className="vertical-timeline-element-title text-lg font-semibold">
                                    {data.title}
                                </h3>
                                <h4 className="vertical-timeline-element-subtitle font-bold text-gray-600">
                                    {data.company}
                                </h4>
                                {/* <p>
                                    <span className="max-sm:text-sm">
                                        {data.duration}
                                    </span>
                                </p> */}
                                <p>
                                    <span className="max-sm:text-sm ">
                                        {data.description}{" "}
                                    </span>
                                </p>
                                <p>
                                    <span className="font-bold">Tech:</span>{" "}
                                    <span className="max-sm:text-sm">
                                        {data.tech}.
                                    </span>
                                </p>
                                <p>
                                    <span className="font-bold">Skills:</span>
                                </p>
                                <ul>
                                    {data.skills.map((skill, index) => (
                                        <li
                                            className="flex gap-3 items-start"
                                            key={index}
                                        >
                                            ☑{" "}
                                            <span className="max-sm:text-sm">
                                                {skill}.
                                            </span>
                                        </li>
                                    ))}
                                </ul>
                                {data.note && (
                                    <p>
                                        <span className="italic text-sm text-gray-600">
                                            Note: {data.note}
                                        </span>
                                    </p>
                                )}
                            </VerticalTimelineElement>
                        ))}
                    </VerticalTimeline>
                </div>
            )}
        </div>
    );
};

export default Career;
